import React, { useState } from "react";
import { GrTwitter, GrLinkedinOption, GrInstagram } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import Halvestlogo from "../Assets/SVGS/Halvestlogo";
import mixpanel from "mixpanel-browser";

// <div className="container gap-4 flex flex-col lg:flex-row justify-between mx-auto m-8">
//   <div className="flex-1 m-auto lg:m-0">
//     <img
//       src={require("../Assets/Logo/halvest.png")}
//       className="w-[187px] cursor-pointer h-[30px] m-auto lg:m-0"
//     />
//     <h5 className="opacity-0 mt-8 text-base font-[400] max-w-[320px] leading-6  text-[#475467]">
//       Lorem ipsum dolor sit amet consectetur. Sit auctor suspendisse purus
//       sem iaculis ipsum.
//     </h5>
//   </div>
//   <div className="flex-[2]  gap-4 flex flex-col items-center lg:items-start lg:flex-row">
//     <div className="flex-[2]  gap-4 flex  lg:items-start ">
//       <div className="flex-1">
//         <h5 className="cursor-pointer mb-4 text-base font-[600] max-w-[320px] leading-6  text-[#475467]">
//           Product
//         </h5>
//         <h6 className="cursor-pointer mb-4 text-sm font-[600] max-w-[320px] leading-6  text-[#101828]">
//           SME Investing
//         </h6>
//         <h6 className="cursor-pointer mb-4 text-sm font-[600] max-w-[320px] leading-6  text-[#101828]">
//           Real Estate
//         </h6>
//         <h6 className="cursor-pointer mb-4 text-sm font-[600] max-w-[320px] leading-6  text-[#101828]">
//           Equity
//         </h6>
//       </div>
//       <div className="flex-1">
//         <h5
//           onClick={() => {
//             navigate("/about-us");
//           }}
//           className=" mb-4 cursor-pointer text-base font-[600] max-w-[320px] leading-6  text-[#475467]"
//         >
//           About
//         </h5>
//         {/* <h6
//           onClick={() => {
//             navigate("/our-story");
//           }}
//           className="cursor-pointer mb-4 text-sm font-[600] max-w-[320px] leading-6  text-[#101828]"
//         >
//           Our Story
//         </h6> */}
//         <h6 className="cursor-pointer  mb-4 text-sm font-[600] max-w-[320px] leading-6  text-[#101828]">
//           Success Stories
//         </h6>
//         <h6 className="cursor-pointer mb-4 text-sm font-[600] max-w-[320px] leading-6  text-[#101828]">
//           FAQ
//         </h6>
//       </div>
//     </div>
//     <div className="flex-1">
//       <h5 className=" mb-4 text-base font-[600] max-w-[320px] leading-6  text-[#475467]">
//         Contact
//       </h5>
//       <h6 className=" mb-8 text-sm font-[600] max-w-[320px] leading-6  text-[#101828]">
//         Nigeria Address: 12f, Tola Adewunmi street, Maryland Estate, Lagos
//       </h6>
//       <h6 className=" mb-8 text-sm font-[600] max-w-[320px] leading-6  text-[#101828]">
//         USA Address: 2055 Limestone RD STE 200C Wilmington, DE 19808
//       </h6>
//       <h6 className=" mb-8 text-sm font-[600] max-w-[320px] leading-6  text-[#101828]">
//         support@halvestco.com
//       </h6>
//     </div>
//   </div>
// </div>
const Footer = () => {
  const navigate = useNavigate();
  return (
    <section className=" border-b  bg-[rgba(21,154,168,1)] px-3 py-8 md:p-[104px]">
      <div className="flex flex-col md:flex-row justify-between ">
        <div className="  lg:w-[347px] mb-4 medium:mb-0">
          <ul className="text-white text-xs">
            <p className="text-[rgba(253,254,255,0.93)]  text-base">
              Connect with us
            </p>

            <li className="mt-1 text-[rgba(253,254,255,0.93)] text-sm ">
              Phone number: +2347042915411, +1 5197293470
            </li>
            <li className="mt-1 text-[rgba(253,254,255,0.93)] text-sm">
              Email: hello@halvestco.com
            </li>
          </ul>
          <div className="flex items-center gap-4 mt-8">
            {/* <a  href={"https://docsend.com/view/w27seqhmzqj2bx3j"}
                
                target="_blank" className="w-[27px] h-[27px] ">
              <GrTwitter className="text-white cursor-pointer transition-all hover:text-[rgba(239,247,255,0.62)]" />
            </a> */}
            <a
              href={"https://www.linkedin.com/company/halvestco/"}
              target="_blank"
              className="w-[27px] h-[27px]"
            >
              <GrLinkedinOption
                size={20}
                className="text-white cursor-pointer transition-all hover:text-[rgba(239,247,255,0.62)]"
              />
            </a>
            <a
              href={
                "https://www.instagram.com/halvestco?igsh=MWx3N2dta2ZyendpZg=="
              }
              target="_blank"
              className="w-[27px] h-[27px]"
            >
              <GrInstagram
                size={20}
                className="text-white cursor-pointer transition-all hover:text-[rgba(239,247,255,0.62)]"
              />
            </a>
          </div>
        </div>
        <div className="lg:w-64 mb-4 medium:mb-0 md:mx-4">
          <p className="text-[rgba(253,254,255,0.93)] text-sm">Canada</p>
          <ul className="text-[rgba(253,254,255,0.93)]">
            11 Oxfordshire Lane Kitchener ON CA. N2H 0B6.
          </ul>
        </div>
        {/* <div className="lg:w-64 mb-4 medium:mb-0 md:mx-4">
          <p className="text-[rgba(253,254,255,0.93)] text-sm">Malaysia</p>
          <ul className="text-[rgba(253,254,255,0.93)]">
            Jalan Melati, Taman Melati, Setapak, Kuala Lumpur, Malaysia
          </ul>
        </div> */}
        <div className="lg:w-64 mb-4 medium:mb-0 md:mx-4">
          <p className="text-[rgba(253,254,255,0.93)] text-sm">Nigeria</p>
          <ul className="text-[rgba(253,254,255,0.93)]">
            12f Tola Adewunmi Street, Maryland Estate, Lagos Nigeria
          </ul>
        </div>
      </div>
      <div className="flex justify-between items-center  w-full gap-6 flex-wrap ">
        <div className="flex justify-between items-start  w-[65%] gap-6 flex-wrap ">
          <Halvestlogo />

          <div className="flex justify-around flex-1 items-center gap-3 max-w-[500px]">
            <div>
              {/* <p className="text-[rgba(253,254,255,0.93)] font-[500] text-base cursor-pointer transition-all hover:text-[rgba(239,247,255,0.62)]">
              Pages
            </p> */}
              <p
                onClick={() => {
                  // mixpanel.track_pageview({ page: "Website-AboutUs-Page" });
                  navigate("/about-us");
                }}
                className="text-[rgba(253,254,255,0.93)] mb-1 text-base cursor-pointer transition-all hover:text-[rgba(239,247,255,0.62)]"
              >
                About Us
              </p>
              <p
                onClick={() => {
                  // mixpanel.track_pageview({ page: "Website-Membership-Page" });
                  navigate("/membership");
                }}
                className="text-[rgba(253,254,255,0.93)] text-base cursor-pointer transition-all hover:text-[rgba(239,247,255,0.62)]"
              >
                Membership
              </p>
            </div>
            <div>
              {/* <p className="text-[rgba(253,254,255,0.93)] font-[500] text-base cursor-pointer transition-all hover:text-[rgba(239,247,255,0.62)]">
              Legal Documents
            </p> */}
              <p className="mb-1">
                <a
                  rel="noreferrer"
                  href={"https://halvestco.com/Legal/Terms.docx.pdf"}
                  // download={`HALVEST INVESTMENT PLATFORM AGREEMENT`}
                  target="_blank"
                  className="text-[rgba(253,254,255,0.93)] mb-2 text-base cursor-pointer transition-all hover:text-[rgba(239,247,255,0.62)]"
                >
                  Terms and Conditions
                </a>
              </p>
              <p>
                <a
                  rel="noreferrer"
                  href={"https://halvestco.com/Legal/Privacy%20Policy.docx.pdf"}
                  // download={`HALVEST INVESTMENT PLATFORM AGREEMENT`}
                  target="_blank"
                  className="text-[rgba(253,254,255,0.93)] text-base cursor-pointer transition-all hover:text-[rgba(239,247,255,0.62)]"
                >
                  Privacy Policy
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="flex gap-6 ">
          <img
            src={require("../Assets/Images/ndic.png")}
            width="254"
            height="66"
            alt="logo"
            className="object-contain"
          />
          {/* <img
            src="/Images/NDPR.png"
            width="108"
            height="66"
            alt="logo"
            className="object-contain rounded-[8px]"
          /> */}
        </div>
      </div>

      {/* <div className="container mx-auto pt-8 md:px-[104px] border-t-[rgba(253,254,255,0.93)] border-t-[0.5px] gap-4 flex flex-col lg:flex-row justify-between items-center">
        <h5 className="text-base font-[400] leading-6  text-[rgba(253,254,255,0.93)]">
          {`© ${new Date().getFullYear()} Halvest. All rights reserved.`}
        </h5>
        <div className="flex gap-6 items-center">
          <img
            src={require("../Assets/Icons/linkedin.png")}
            width="24px"
            height="24px"
            alt="logo"
            className="object-contain cursor-pointer"
          />
          <img
            src={require("../Assets/Icons/facebook.png")}
            width="24px"
            height="24px"
            alt="logo"
            className="object-contain cursor-pointer"
          />
          <img
            src={require("../Assets/Icons/instagram.png")}
            width="32px"
            height="32px"
            alt="logo"
            className="object-contain cursor-pointer"
          />
        </div>
      </div> */}
    </section>
  );
};

export default Footer;
