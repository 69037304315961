import React, { useEffect, useMemo, useState } from "react";
import Header from "../Components/Header";
import { FiPlusCircle, FiMinusCircle } from "react-icons/fi";
import Footer from "../Components/Footer";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { BsDash } from "react-icons/bs";
import mixpanel from "mixpanel-browser";
import SEO from "../Components/Seo";
const Pricing = () => {
  const [currentPhilosophies, setCurrentPhilosophies] = useState(null);
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const [animate, setAnimate] = useState(false);
  const Philosophies = useMemo(
    () => [
      {
        title: "What Membership plans available? ",
        subTitle: "We have two Membership Plans;",
        answer: (
          <ul className="list-disc list-outside pl-4">
            <li>
              <h5
                className={`mt-2 text-base font-[400] leading-6  text-[#344054] transform transition-all duration-500 ease-in-out ${
                  animate
                    ? "opacity-100 translate-x-0"
                    : "opacity-0 translate-x-3"
                }`}
              >
                <span className="font-[600]">YEARLY SUBSCRIPTION:</span> For a
                fee of #250,000 per annum, you gain unlimited access to all our
                investment opportunities and events.
              </h5>
            </li>
            <li>
              <h5
                className={`mt-2 text-base font-[400] leading-6  text-[#344054] transform transition-all duration-500 ease-in-out ${
                  animate
                    ? "opacity-100 translate-x-0"
                    : "opacity-0 translate-x-3"
                }`}
              >
                <span className="font-[600]">PAY-AS-YOU-GO:</span> you can
                choose not to pay the annual subscription fee. Instead, a 2%
                administrative fee will be applied to all investments you make
                within the community.
              </h5>
            </li>
          </ul>
        ),
      },
      {
        title: "Is the membership fee part of my investment?",

        answer: (
          <h5
            className={`mt-2 text-base font-[400] leading-6  text-[#475467] transform transition-all duration-500 ease-in-out ${
              animate ? "opacity-100 translate-x-0" : "opacity-0 translate-x-3"
            }`}
          >
            No, the membership fee is what you pay for getting all the benefits
            in the Community. You must have your own money set aside to take
            advantage of investment opportunities that we will be sharing.
          </h5>
        ),
      },
      {
        title:
          "What will happen when my subscription expires and I do not want to renew?",
        answer: (
          <h5
            className={`mt-2 text-base font-[400] leading-6  text-[#475467] transform transition-all duration-500 ease-in-out ${
              animate ? "opacity-100 translate-x-0" : "opacity-0 translate-x-3"
            }`}
          >
            Although we hate to see you go, we understand there might be other
            reasons for people to leave. If this happens, you will be removed
            from all our platforms and will not have access to our learning
            portal and investments. However, you will continue to receive
            updates and returns on the investments you participated in when your
            subscription was active.
          </h5>
        ),
      },
      {
        title:
          " How does Halvest update me on the Status of my investment Progress? ",
        answer: (
          <h5
            className={`mt-2 text-base font-[400] leading-6  text-[#475467] transform transition-all duration-500 ease-in-out ${
              animate ? "opacity-100 translate-x-0" : "opacity-0 translate-x-3"
            }`}
          >
            We provide regular updates and reports on each of your investments,
            ensuring transparency and accountability.{" "}
          </h5>
        ),
      },
      {
        title:
          "Is there a Community support team available to assist investors? ",
        answer: (
          <h5
            className={`mt-2 text-base font-[400] leading-6  text-[#475467] transform transition-all duration-500 ease-in-out ${
              animate ? "opacity-100 translate-x-0" : "opacity-0 translate-x-3"
            }`}
          >
            Yes, Halvest has a dedicated Community support team that can be
            reached at support@halvestco.com, ready to assist investors with any
            inquiries, concerns, or assistance they may need.
          </h5>
        ),
      },
    ],
    [animate]
  );
  const renderPhilosophies = (item, index) => {
    return (
      <div
        key={index}
        className="w-[100%] border-t border-t-[#EAECF0] pt-6 mb-8 flex justify-between "
      >
        <div className="w-[90%]">
          <h1 className="  text-xl font-[600]   leading-[30px]  text-[#101828]">
            {item.title}
          </h1>

          <div
            style={{
              display: index === currentPhilosophies ? "block" : "none",
            }}
          >
            {item.answer}
          </div>
        </div>
        <div className="w-[5%]">
          {index === currentPhilosophies ? (
            <FiMinusCircle
              onClick={() => {
                setCurrentPhilosophies(-1);
              }}
              color={"#98A2B3"}
              size={"24px"}
              className="cursor-pointer transform transition hover:scale-105 duration-400 ease-in-out"
            />
          ) : (
            <FiPlusCircle
              className="cursor-pointer transform transition hover:scale-105 duration-400 ease-in-out"
              onClick={() => {
                setAnimate(false);
                mixpanel.track(`Opened-FAQ-${index + 1}`);
                setCurrentPhilosophies(index);
                setTimeout(() => {
                  setAnimate(true);
                }, []);
              }}
              color={"#98A2B3"}
              size={"24px"}
            />
          )}
        </div>
      </div>
    );
  };

  const freedata = [
    { title: "2% per deal" },
    { title: "Access to most investments" },
    { title: "Discounted Fee" },
    { title: "$500 Per annum " },
  ];
  const memberData = [
    { title: "0% - 1%" },
    { title: "First access to  all investments " },
    { title: "Free " },
    { title: "Free " },
  ];

  const titles = [
    { title: "Admin fee" },
    { title: "Investment access" },
    { title: "Halvest events" },
    { title: "Financial coaching session" },
  ];

  const renderItems = (item, index, type) => {
    return (
      <div
        key={index}
        className={`flex px-3 lg:px-4 ${
          type === "titles" ? "justify-start" : "justify-center"
        } items-center h-[64px] w-full ${
          index % 2 ? "bg-[#F9FAFB]" : "bg-transparent"
        } ${item?.title ? "" : "justify-center"} `}
      >
        <h5
          className={`text-sm  ${
            type === "titles" ? "text-start" : "text-center"
          }  font-[700] leading-[20px]  text-[#475467]`}
        >
          {item?.title ? (
            item?.title
          ) : item?.checked ? (
            <IoCheckmarkCircleOutline color="#079455" size={24} />
          ) : (
            <BsDash color="#98A2B3" size={20} />
          )}
        </h5>
      </div>
    );
  };

  const [activePlan, setActivePlan] = useState(1);

  return (
    <div>
      <SEO
        title={"Membership & Plans | Join the Halvest Community Today!"}
        description={
          "Register with Halvest and unlock access to ethical finance and Islamic banking solutions. Sign up for Sharia-compliant investments, halal finance, and sustainable investing options."
        }
        name={"Halvest"}
        type={"article"}
      />
      <Header />
      <section className="bg-[#159AA8]  pt-24  ">
        <div className="container  py-[96px] h-full mx-auto ">
          <div className="max-w-[756px] mx-auto px-10 flex flex-col h-full   justify-center items-center ">
            <h1 className=" text-5xl  text-center lg:text-start lg:text-[60px] font-[600]   leading-tight  text-[#fff]">
              Membership And Fees
            </h1>
            {/* <h5 className="  text-base lg:text-xl mt-3 text-center  lg:text-start font-[400] leading-[30px]  text-[#97E9F2]">
              Simple, transparent pricing that grows with you. Try any plan free
              for 30 days.
            </h5> */}
          </div>
        </div>
      </section>
      <section className="container mx-auto px-10 py-[96px] flex flex-col lg:flex-row gap-[64px] bg-[#fff] ">
        <div className=" flex flex-1 flex-col gap-5  ">
          <h1 className=" text-3xl lg:text-4xl   font-[600]   leading-[44px]  text-[#101828]">
            Exclusive access to all our investment opportunities if you become a
            member today.
          </h1>
          <h5 className="  text-base lg:text-[18px]  lg:text-start font-[400] leading-[30px]  text-[#475467]">
            Are you an executive or a high income earner who seeks high growth
            investments with maximum returns? Our Plan gives you access to
            high-growth investments and personalized services or tools that
            suits your ideal lifestyle.
          </h5>
        </div>
        <div className="flex-[1.2] gap-8 flex-wrap flex">
          <img
            src={require("../Assets/Images/pexels.png")}
            className="w-[750px] h-[500px] rounded-[24px] object-cover"
          />
        </div>
      </section>
      <section className="container mx-auto px-10 py-[96px] flex  flex-col lg:flex-row gap-[64px] bg-[#fff] ">
        <div className=" flex  flex-1 items-end overflow-scroll  ">
          <div
            // onClick={() => {
            //   setActivePlan(0);
            // }}
            className={`py-6 border-b-[2px] border-b-transparent flex-1 flex justify-end flex-col`}
          >
            <div className="my-4 w-full">
              {/* <div className="flex px-3 lg:px-4 items-center h-[64px] w-full">
                <h5 className="  text-sm   font-[700] leading-[20px]  text-[#128591]">
                  Reporting and analytics
                </h5>
              </div>
              <div className="flex px-3 lg:px-4 items-center h-[64px] w-full bg-[#F9FAFB]">
                <h5 className="  text-sm   font-[700] leading-[20px]  text-[#475467]">
                  Analytics
                </h5>
              </div> */}
              {titles.map((item, index) => renderItems(item, index, "titles"))}
              <div
                onClick={() => {
                  mixpanel.track("Get-Started-Clicked");
                  window.location = "https://app.halvestco.com/register";
                }}
                className="mx-6"
              >
                <button className="opacity-0  rounded-[8px] text-sm w-full h-[48px] flex justify-center items-center  lg:mx-0 bg-[#159AA8] text-[#fff] font-bold mt-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                  Get Started
                </button>
              </div>
            </div>
          </div>
          {/* <div
            // onClick={() => {
            //   setActivePlan(0);
            // }}
            className={`py-6 w-[405px]  border-y-[2px] ${
              false ? "border-[#159AA8]" : "border-transparent"
            } rounded-2xl  transition transform duration-300 ease-in-out`}
          >
            <div className="px-6 flex-col flex justify-center items-center">
              <img
                src={require("../Assets/Images/Featured icon (4).png")}
                alt=""
                className="w-12 h-12"
              />
              <div className="flex mb-3 mt-5 border border-[#FEDF89] bg-[#FFFAEB] rounded-[8px] justify-center items-center w-[136px] h-9">
                <h5 className="  text-[18px]   font-[500] leading-[28px]  text-[#B54708]">
                  Non Members
                </h5>
              </div>
              <div className="flex gap-1 items-center">
                <h3 className="  text-3xl   my-2 font-[700] leading-[50px]  text-[#101828]">
                  Free
                </h3>
              </div>
              <h5 className="  text-base mt-4 text-center w-[80%]   font-[400] leading-[24px]  text-[#475467]">
                Advanced features and reporting, better workflows and
                automation.
              </h5>

              <button className="rounded-[8px] mb-5 text-sm w-full h-[48px] flex justify-center items-center mx-auto lg:mx-0 bg-[#159AA8] text-[#fff] font-bold mt-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                Get Started
              </button>
            </div>
            <div className="my-4 w-full">
              <div className="flex mt-10 justify-center items-center h-[64px] w-full bg-[#F9FAFB]">
                <h5 className="  text-sm   font-[700] leading-[20px]  text-[#475467]">
                  Advanced
                </h5>
              </div>
              {data.map(renderItems)}
              <div className="mx-6">
                <button className="rounded-[8px] text-sm w-full h-[48px] flex justify-center items-center  lg:mx-0 bg-[#159AA8] text-[#fff] font-bold mt-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                  Get Started
                </button>
              </div>
            </div>
          </div> */}
          <div
            // onClick={() => {
            //   setActivePlan(1);
            // }}
            // className={`py-6 w-[405px]  border-[2px] ${
            //   true ? "border-[#159AA8]" : "border-transparent"
            // } rounded-2xl transition transform duration-300 ease-in-out `}
            className={`py-6 w-[405px]   hover:border-[#FEDF89] border-transparent border-[2px] rounded-2xl  transition transform duration-300 ease-in-out`}
          >
            <div className="px-6 flex-col flex justify-center items-center">
              <img
                src={require("../Assets/Images/Featured icon (4).png")}
                alt=""
                className="w-12 h-12"
              />
              <div className="flex mb-3 mt-5 border border-[#FEDF89] bg-[#FFFAEB] rounded-[8px] justify-center items-center w-[165px] sm:w-[175px] h-9">
                <h5 className="  text-[14px] sm:text-base   font-[500] leading-[28px]  text-[#B54708]">
                  Basic Membership
                </h5>
              </div>

              <div className="flex flex-col gap-1 my-2 items-center">
                <h3 className="  text-3xl min-w-[230px] text-center    font-[700] leading-[50px]  text-[#101828]">
                  Free
                </h3>
                <h3 className="  text-2xl opacity-0 font-[700]   text-[#101828]">
                  per annum
                </h3>
              </div>
              <h5 className="  text-base mt-4 text-center w-[60%]   font-[400] leading-[24px]  text-[#475467]">
                Start investing today with our Free plan
              </h5>

              {/* <button
                onClick={() => {
                  window.location = "https://app.halvestco.com/register";
                }}
                className="rounded-[8px] mb-5 text-sm w-full h-[48px] flex justify-center items-center mx-auto lg:mx-0 bg-[#159AA8] text-[#fff] font-bold mt-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
              >
                Get Started
              </button> */}
            </div>
            <div className="my-4 w-full">
              {/* <div className="flex mt-10 justify-center items-center h-[64px] w-full bg-[#F9FAFB]">
                <h5 className="  text-sm   font-[700] leading-[20px]  text-[#475467]">
                  Advanced
                </h5>
              </div> */}
              {freedata.map(renderItems)}
              <div className="mx-6">
                <button
                  onClick={() => {
                    mixpanel.track("Get-Started-Clicked");
                    window.location = "https://app.halvestco.com/register";
                  }}
                  className="  rounded-[8px] text-sm w-full h-[48px] flex justify-center items-center  lg:mx-0 bg-[#159AA8] text-[#fff] font-bold mt-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
                >
                  Get Started
                </button>
              </div>
            </div>
          </div>
          <div
            // onClick={() => {
            //   setActivePlan(1);
            // }}
            className={`py-6 w-[405px]  border-[2px] hover:border-[#159AA8] border-transparent rounded-2xl transition transform duration-300 ease-in-out `}
          >
            <div className="px-6 flex-col flex justify-center items-center">
              <img
                src={require("../Assets/Images/Featured icon (5).png")}
                alt=""
                className="w-12 h-12"
              />
              <div className="flex bg-[E4F9FC] mb-3 mt-5 border border-[#97E9F2] bg-[#E4F9FC] rounded-[8px] justify-center items-center  w-[165px] sm:w-[185px] h-9">
                <h5 className="    text-[14px] sm:text-base   font-[500] leading-[28px]  text-[#128591]">
                  Premium Membership
                </h5>
              </div>
              <div className="flex flex-col gap-1 my-2 items-center">
                <h3 className="  text-3xl    font-[700] leading-[50px]  text-[#101828]">
                  $300(₦250K)
                </h3>
                <h3 className="  text-2xl  font-[700]  text-[#101828]">
                  per annum
                </h3>
              </div>
              <h5 className="  text-base mt-4 text-center w-[80%]   font-[400] leading-[24px]  text-[#475467]">
                Enjoy more benefits with our Membership plan
              </h5>

              {/* <button
                onClick={() => {
                  window.location = "https://app.halvestco.com/register";
                }}
                className="rounded-[8px] mb-5 text-sm w-full h-[48px] flex justify-center items-center mx-auto lg:mx-0 bg-[#159AA8] text-[#fff] font-bold mt-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
              >
                Get Started
              </button> */}
            </div>
            <div className="my-4 w-full">
              {/* <div className="flex mt-10 justify-center items-center h-[64px] w-full bg-[#F9FAFB]">
                <h5 className="  text-sm   font-[700] leading-[20px]  text-[#475467]">
                  Advanced
                </h5>
              </div> */}
              {memberData.map(renderItems)}
              <div className="mx-6">
                <button
                  onClick={() => {
                    mixpanel.track("Get-Started-Clicked");
                    window.location = "https://app.halvestco.com/register";
                  }}
                  className="  rounded-[8px] text-sm w-full h-[48px] flex justify-center items-center  lg:mx-0 bg-[#159AA8] text-[#fff] font-bold mt-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
                >
                  Get Started
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container mx-auto px-10 py-[96px] flex flex-col lg:flex-row gap-[64px] bg-[#fff] ">
        <div className=" flex flex-[2.5] flex-col gap-5  ">
          <h1 className=" text-3xl lg:text-4xl   font-[600]   leading-[44px]  text-[#101828]">
            Membership Perks
          </h1>
          <h5 className="  text-base lg:text-[18px]  lg:text-start font-[400] leading-[30px]  text-[#475467]">
            Save money and time to access deals on our annual membership plan.
          </h5>
        </div>
        <div className="flex-[5] gap-8 flex-wrap flex">
          <div className="flex w-[380px] flex-col justify-between h-[224px] rounded-xl bg-[#F5FBFF] p-[24px]">
            <img
              src={require("../Assets/Icons/Featured icon.png")}
              className="w-[48px] h-[48px]"
            />
            <div className=" flex flex-col gap-2  ">
              <h3 className=" text-2xl    font-[600]   leading-[32px]  text-[#101828]">
                Zero or Discounted
              </h3>
              <h5 className="  text-base   font-[400] leading-[24px]  text-[#475467]">
                Admin fee
              </h5>
            </div>
          </div>
          <div className="flex w-[380px] flex-col justify-between h-[224px] rounded-xl bg-[#FBFAFF] p-[24px]">
            <img
              src={require("../Assets/Icons/Featured icon (1).png")}
              className="w-[48px] h-[48px]"
            />
            <div className=" flex flex-col gap-2  ">
              <h3 className=" text-2xl    font-[600]   leading-[32px]  text-[#101828]">
                N500K+
              </h3>
              <h5 className="  text-base   font-[400] leading-[24px]  text-[#475467]">
                Worth of annual savings
              </h5>
            </div>
          </div>
          <div className="flex w-[380px] flex-col justify-between h-[224px] rounded-xl bg-[#F6FEF9] p-[24px]">
            <img
              src={require("../Assets/Icons/Featured icon (2).png")}
              className="w-[48px] h-[48px]"
            />
            <div className=" flex flex-col gap-2  ">
              <h3 className=" text-2xl    font-[600]   leading-[32px]  text-[#101828]">
                $500+
              </h3>
              <h5 className="  text-base   font-[400] leading-[24px]  text-[#475467]">
                Savings on courses and events
              </h5>
            </div>
          </div>
          <div className="flex w-[380px] flex-col justify-between h-[224px] rounded-xl bg-[#FEFAF5] p-[24px]">
            <img
              src={require("../Assets/Icons/Featured icon (3).png")}
              className="w-[48px] h-[48px]"
            />
            <div className=" flex flex-col gap-2  ">
              <h3 className=" text-2xl    font-[600]   leading-[32px]  text-[#101828]">
                Free
              </h3>
              <h5 className="  text-base   font-[400] leading-[24px]  text-[#475467]">
                Access to Halvest Events & Financing Coaching
              </h5>
            </div>
          </div>
        </div>
      </section>
      <section className=" py-[96px] bg-[#F9FAFB] ">
        <div className="max-w-[756px] mx-auto px-10 flex flex-col gap-5 justify-center items-center ">
          <h1 className=" text-3xl lg:text-4xl text-center  font-[600]   leading-[44px]  text-[#101828]">
            Frequently asked questions
          </h1>
          <h5 className="  text-base lg:text-xl text-center lg:text-start font-[400] leading-[30px]  text-[#475467]">
            Everything you need to know about the product and billing.
          </h5>
        </div>
        <div className="max-w-[768px] mt-[64px]  mx-10 lg:mx-auto">
          {Philosophies.map(renderPhilosophies)}
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Pricing;
