import React from "react";

const ChatRose = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        className=" fill-[#101828]"
        d="M35.3483 3.73638L35.2196 3.7073C33.9676 3.4244 32.8136 3.16367 31.819 3.12905C30.6694 3.08904 29.6059 3.3398 28.6148 4.13455C27.576 4.96762 27.1252 6.05107 26.9263 7.24336C26.7478 8.3138 26.7479 9.64365 26.7481 11.148L26.7481 11.2777V29.6672C26.7481 30.1386 26.7481 30.3743 26.8945 30.5207C27.041 30.6672 27.2767 30.6672 27.7481 30.6672H48.7888L48.9256 30.6672H48.9257C50.4224 30.6674 51.7609 30.6675 52.8406 30.4718C54.0624 30.2502 55.1453 29.7484 55.9601 28.6406C56.7244 27.6016 56.9558 26.5277 56.8498 25.3594C56.7602 24.3727 56.4181 23.2647 56.0579 22.0975L56.0168 21.9643C53.2314 12.9335 45.2677 5.97594 35.3483 3.73638ZM21.395 18.0566C14.3375 18.0566 8.33008 22.912 8.33008 29.2151C8.33008 30.0189 9.02582 30.6705 9.88406 30.6705H21.395C22.2532 30.6705 22.949 30.0189 22.949 29.2151V19.5121C22.949 18.7083 22.2532 18.0566 21.395 18.0566ZM10.9247 34.4239L10.774 34.4238C9.33255 34.4236 8.00368 34.4233 6.92665 34.6434C5.66202 34.9018 4.61337 35.4834 3.86407 36.6437C3.1874 37.6915 2.98219 38.7143 3.2205 39.8243C3.41339 40.7227 3.90339 41.6471 4.38074 42.5477L4.44783 42.6743C7.40544 48.2613 13.8958 52.0484 21.3184 52.0484C22.2195 52.0484 22.95 51.3522 22.95 50.4933L22.95 35.979C22.95 35.5665 22.7781 35.171 22.4721 34.8793C22.1662 34.5877 21.7512 34.4239 21.3185 34.4239H10.9247ZM26.7481 36.0403C26.7481 35.1476 27.4703 34.4238 28.3613 34.4238H49.8707C50.7617 34.4238 51.484 35.1476 51.484 36.0403C51.484 45.5939 44.5151 53.5172 35.1699 56.129L35.0419 56.1648C33.8779 56.4903 32.7849 56.7961 31.8233 56.8647C30.6942 56.9452 29.6545 56.7136 28.6611 55.9579C27.6081 55.1568 27.1389 54.1 26.9318 52.9192C26.7478 51.8693 26.7479 50.5665 26.7481 49.1029L26.7481 48.9722V36.0403Z"
        fill="white"
      />
    </svg>
  );
};

export default ChatRose;
