import React, { useEffect, useState } from "react";
import { ImWhatsapp } from "react-icons/im";
import { MdOutlineClose } from "react-icons/md";
const Whatsapp = () => {
  const [animate, setAnimate] = useState(false);
  const [close, setClose] = useState(true);

  useEffect(() => {
    let timeout;
    if (animate === true) {
      setClose(false);
    } else if (animate === false && close === false) {
      timeout = setTimeout(() => {
        setClose(true);
      }, 300);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [animate]);

  return (
    <>
      <div className="fixed bottom-[20px] z-50 flex flex-col  items-end right-[16px] lg:right-[36px] ">
        <div
          className={` translate shadow-lg rounded-2xl overflow-hidden  transform transition-all duration-300 ease-in-out ${
            animate
              ? "mb-6 opacity-100 translate-y-0"
              : "opacity-0 translate-y-5"
          }`}
        >
          {close ? (
            <></>
          ) : (
            <div className=" w-[360px]">
              <div className="w-full bg-[rgb(6,139,136)]  flex  py-6 px-5 h-[100px]">
                <div className="w-full relative items-center flex gap-4 h-full">
                  <div className="w-[52px] h-[52px]  relative  bg-[rgb(6,139,136)] flex justify-center items-center">
                    <img
                      className="w-full h-full rounded-full"
                      src={require("../Assets/Images/Halvest-WhatsApp.jpg")}
                    />
                    <div className="w-[8px] h-[8px] rounded-full bg-[rgb(74,213,4);] absolute bottom-[3px] right-[4px]"></div>
                  </div>
                  <div>
                    <h3 className="text-[#fff] text-[16px] font-[700] leading-[20px] ">
                      Halvest
                    </h3>

                    <h5 className="text-[rgb(255,255,255)] text-[13px] leading-[18px] mt-1">
                      Typically replies in minutes
                    </h5>
                  </div>
                </div>
                <div
                  className="cursor-pointer rounded-full p-1 "
                  onClick={() => {
                    setAnimate(false);
                  }}
                >
                  <MdOutlineClose className="cursor-pointer" color={"#FFf"} />
                </div>
              </div>
              <div className="bg-[rgba(230,221,212,1)]">
                <div className="h-[152px] w-full bg-[url('./Assets/Images/whatsapp-bg.png')] relative ">
                  <div className="absolute px-4 py-5 top-0 left-0 w-full h-full bg-[rgba(230,221,212,0.9)] ">
                    {/* <div className="bg-[#fff] rounded-xl w-[60px] h-[24px]  p-1"> */}
                    {/* <img
            alt="Button Icon"
            className=" rounded-xl h-[50px] object-center  object-cover  p-1"
            src={"/Assets/lineear-loader-2.gif"}
          /> */}
                    {/* </div> */}

                    <div className="w-[148px] h-[108px] bg-[#fff] px-[14px] pt-[7px] pb-[6px] rounded-b-lg rounded-tr-lg">
                      <h3 className="text-[#00000066] text-[13px] font-[700] leading-[18px] ">
                        Halvest
                      </h3>

                      <h5 className="text-[#111] text-[13.5px] font-[400] mt-1">
                        Hi there 👋
                      </h5>
                      <h5 className="text-[#111] text-[13.5px] font-[400] mt-4">
                        How can I help you?
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="h-[90px] w-full bg-[#FFF] flex items-center justify-center ">
                <a
                  href="https://api.whatsapp.com/send?phone=2347042915411&text=Halvest"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="w-[320px] cursor-pointer h-[40px] bg-[rgb(6,139,136)] rounded-[24px] gap-2 flex items-center justify-center">
                    <ImWhatsapp color="rgb(231, 241, 255)" size={24} />
                    <h5 className="text-[rgb(255,255,255)] text-[14px] font-[400] ">
                      Start Chat
                    </h5>
                  </div>
                </a>
              </div>
            </div>
          )}
        </div>
        <div
          onClick={() => {
            setAnimate((prev) => !prev);
          }}
          className="w-[64px] cursor-pointer h-[64px] relative rounded-full bg-[rgb(6,139,136)] flex justify-center items-center"
        >
          <ImWhatsapp color="rgb(231, 241, 255)" size={34} />
          <div className="w-[10px] h-[10px] rounded-full bg-[red] absolute top-[3px] right-[5px]"></div>
        </div>
      </div>
    </>
  );
};

export default Whatsapp;
