import React, { useEffect } from "react";
import Header from "../Components/Header";
import Hero from "../Components/Hero";
import Discover from "../Components/Discover";
import Opportunities from "../Components/Opportunities";
import WhatYouWillGet from "../Components/WhatYouWillGet";
import Articles from "../Components/Articles";
import BecomeMember from "../Components/BecomeMember";
import Footer from "../Components/Footer";
import SEO from "../Components/Seo";

const Home = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      <SEO
        title={
          "Ethical Finance & Islamic Banking | Halvest - Africa's #1 Digital Investment Platform"
        }
        description={
          "Discover ethical finance and Islamic banking solutions with Halvest. We offer Sharia-compliant investments, halal finance, and sustainable investing options with competitive returns."
        }
        name={"Halvest"}
        type={"article"}
      />
      <Header />
      <Hero />
      <Discover />
      <Opportunities />
      <WhatYouWillGet />
      {/* <Articles /> */}
      <BecomeMember />
      <Footer />
    </div>
  );
};

export default Home;
