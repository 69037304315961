import React from "react";

const Discover = () => {
  return (
    <section className="bg-white border-b py-8">
      <div className="container max-w-[1128px] mx-auto m-8">
        <h1 className="w-full px-2  text-3xl sm:text-4xl font-[600] max-w-[768px] mx-[auto] leading-tight text-center text-[#101828]">
          Discover why investors choose us to build their wealth the ethical
          way.
        </h1>
        {/* <h5 className="w-full mt-5 text-base lg:text-xl font-[400]  lg:max-w-[768px]  mx-[auto] leading-tight text-center text-[#475467]">
          Lorem ipsum dolor sit amet consectetur. Ornare eget mauris ut
          consectetur mi ut dis.
        </h5> */}
        <div className="flex flex-col gap-16 sm:gap-20 my-4 sm:my-16 pt-12">
          <div className="w-full hidden   justify-center lg:flex items-center gap-6">
            <img
              src={require("../Assets/Icons/icon1.png")}
              className="w-[78px] h-[78px]"
            />
            <img
              src={require("../Assets/Icons/line.png")}
              className="w-[310px] h-[12px]"
            />
            <img
              src={require("../Assets/Icons/icon2.png")}
              className="w-[78px] h-[78px]"
            />
            <img
              src={require("../Assets/Icons/line.png")}
              className="w-[310px] h-[12px]"
            />
            <img
              src={require("../Assets/Icons/icon3.png")}
              className="w-[78px] h-[78px]"
            />
          </div>
          <div className="flex flex-col gap-3 lg:gap-0 lg:flex-row justify-between">
            <div className="flex items-center flex-col gap-4 justify-evenly lg:justify-normal ">
              <img
                src={require("../Assets/Icons/icon1.png")}
                className="w-[39px] h-[39px] lg:hidden"
              />
              <div className="gap-4  w-[256px]">
                <h4 className=" text-xl font-[700]  mx-[auto] text-center text-[#101828]">
                  ETHICAL
                </h4>
                <h4 className="text-base font-[400] mx-[auto] text-center text-[#475467]">
                  Rigorous Sharia compliance screening by our team of experts
                  and in-house sharia scholars
                </h4>
              </div>
            </div>
            <div className="flex items-center flex-col gap-4 justify-evenly lg:justify-normal ">
              <img
                src={require("../Assets/Icons/icon2.png")}
                className="w-[39px] h-[39px] lg:hidden"
              />
              <div className="gap-4 w-[256px]">
                <h4 className=" text-xl font-[700]  mx-[auto] text-center text-[#101828]">
                  COMPETITIVE RETURNS
                </h4>
                <h4 className="text-base font-[400] mx-[auto] text-center text-[#475467]">
                  Credible ethical investment opportunities that are projected
                  to give competitive returns.
                </h4>
              </div>
            </div>
            <div className="flex items-center flex-col gap-4 justify-evenly lg:justify-normal ">
              <img
                src={require("../Assets/Icons/icon3.png")}
                className="w-[39px] h-[39px] lg:hidden"
              />
              <div className="gap-4 w-[256px]">
                <h4 className=" text-xl font-[700]  mx-[auto] text-center text-[#101828]">
                  VERIFIED
                </h4>
                <h4 className="text-base font-[400] mx-[auto] text-center text-[#475467]">
                  Investments in projects that are managed by reliable
                  entrepreneurs with excellent track record.
                </h4>
              </div>
            </div>
          </div>
          <div className="w-full  justify-center hidden lg:flex items-center gap-6">
            <img
              src={require("../Assets/Icons/icon4.png")}
              className="w-[78px] h-[78px]"
            />
            <img
              src={require("../Assets/Icons/line.png")}
              className="w-[310px] h-[12px]"
            />
            <img
              src={require("../Assets/Icons/icon5.png")}
              className="w-[78px] h-[78px]"
            />
            <img
              src={require("../Assets/Icons/line.png")}
              className="w-[310px] h-[12px]"
            />
            <img
              src={require("../Assets/Icons/icon6.png")}
              className="w-[78px] h-[78px]"
            />
          </div>
          <div className="flex flex-col gap-3 lg:gap-0  lg:flex-row justify-between">
            <div className="flex items-center flex-col gap-4 justify-evenly lg:justify-normal ">
              <img
                src={require("../Assets/Icons/icon4.png")}
                className="w-[39px] h-[39px] lg:hidden"
              />
              <div className="gap-4  w-[256px]">
                <h4 className=" text-xl font-[700]  mx-[auto] text-center text-[#101828]">
                  Exclusivity
                </h4>
                <h4 className="text-base font-[400] mx-[auto] text-center text-[#475467]">
                  Exclusive access to investment opportunities that are hard to
                  come by.
                </h4>
              </div>
            </div>

            <div className="flex items-center flex-col gap-4 justify-evenly lg:justify-normal ">
              <img
                src={require("../Assets/Icons/icon5.png")}
                className="w-[39px] h-[39px] lg:hidden"
              />
              <div className="gap-4 w-[256px]">
                <h4 className=" text-xl font-[700]  mx-[auto] text-center text-[#101828]">
                  TRUSTED
                </h4>
                <h4 className="text-base font-[400] mx-[auto] text-center text-[#475467]">
                  A trusted platform to learn and build wealth without feeling
                  guilty.
                </h4>
              </div>
            </div>
            <div className="flex items-center flex-col gap-4 justify-evenly lg:justify-normal ">
              <img
                src={require("../Assets/Icons/icon6.png")}
                className="w-[39px] h-[39px] lg:hidden"
              />
              <div className="gap-4 w-[256px]">
                <h4 className=" text-xl font-[700]  mx-[auto] text-center text-[#101828]">
                  MONITORING
                </h4>
                <h4 className="text-base font-[400] mx-[auto] text-center text-[#475467]">
                  Close and adequate monitoring of all investments to ensure
                  full payment as at when due.
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="max-w-[1216px] rounded-[24px] my-16 mx-auto min-h-[360px] p-6 gap-8 bg-[#159AA8]  flex justify-center items-center  flex-wrap"
        style={{
          backgroundImage: 'url("/Assets/Metrics.png")',
          backgroundPosition: "center",
          backgroundSize: "contain",
        }}
      >
        {/* <div className="flex gap-3 rounded-2xl w-[248px] h-[185px] flex-col items-center border border-[rgba(255,255,255,0.30)] bg-[rgba(255,255,255,0.30)] py-[22px] px-[24px]">
          <h2 className="font-[600] text-[60px] leading-[72px] text-center text-[#fff]">
            100+
          </h2>
          <div>
            <h5 className="font-[600] leading-7 text-[18px] text-center text-[#fff]">
              Active Members
            </h5>
          </div>
        </div> */}
        <div className="flex gap-3 rounded-2xl w-[248px] h-[185px] flex-col items-center border border-[rgba(255,255,255,0.30)] bg-[rgba(255,255,255,0.30)] py-[22px] px-[24px]">
          <h2 className="font-[600] text-[60px] leading-[72px] text-center text-[#fff]">
            $4M+
          </h2>
          <div>
            <h5 className="font-[600] leading-7 text-[18px] text-center text-[#fff]">
              Million Invested
            </h5>
          </div>
        </div>
        <div className="flex gap-3 rounded-2xl w-[248px] h-[185px] flex-col items-center border border-[rgba(255,255,255,0.30)] bg-[rgba(255,255,255,0.30)] py-[22px] px-[24px]">
          <h2 className="font-[600] text-[60px] leading-[72px] text-center text-[#fff]">
            8
          </h2>
          <div>
            <h5 className="font-[600] leading-7 text-[18px] text-center text-[#fff]">
              Countries Represented
            </h5>
          </div>
        </div>
        <div className="flex gap-3 rounded-2xl w-[248px] h-[185px] flex-col items-center border border-[rgba(255,255,255,0.30)] bg-[rgba(255,255,255,0.30)] py-[22px] px-[24px]">
          <h2 className="font-[600] text-[60px] leading-[72px] text-center text-[#fff]">
            65+
          </h2>
          <div>
            <h5 className="font-[600] leading-7 text-[18px] text-center text-[#fff]">
              Investment Campaigns
            </h5>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Discover;
