import mixpanel from "mixpanel-browser";
import React, { useState } from "react";

const BecomeMember = () => {
  return (
    <section className="bg-[#F6FDFE] border-b py-8">
      <div className="container  mx-auto m-8">
        <div className="max-w-[756px] mx-auto px-10 flex flex-col gap-5 justify-center items-center ">
          <h1 className=" text-2xl text-center lg:text-start lg:text-4xl font-[600]   leading-tight  text-[#101828]">
            Get started with Halvest today, fast.
          </h1>
          <h5 className=" lg:mt-5 text-base lg:text-xl text-center lg:text-start font-[400] leading-tight  text-[#475467]">
            We have cracked the ethical investing code.
          </h5>
          <button
            onClick={() => {
              mixpanel.track("Get-Started-Clicked");

              window.location = "https://app.halvestco.com/register";
            }}
            className="rounded-[8px] text-[16px] w-[180px] h-[48px] flex justify-center items-center mx-auto lg:mx-0 bg-[#159AA8] text-[#fff] font-bold mt-3 py-4  shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
          >
            Get Started
          </button>
        </div>
      </div>
    </section>
  );
};

export default BecomeMember;
