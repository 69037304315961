import mixpanel from "mixpanel-browser";
import React, { useState } from "react";
import { BsStarFill } from "react-icons/bs";
import { IoArrowBack, IoArrowForward } from "react-icons/io5";

const items = [
  {
    title: "Investment Opportunities",
    desc: "Access local and foreign investments on our newly built web portal to ensure a smooth investment experience with us.",
  },
  {
    title: "Community",
    desc: "A global community to connect and invest with. Most of our members are successful professionals and entrepreneurs from across 5 countries",
  },
  {
    title: "Educational Resources",
    desc: "Access to learning resources to help you navigate the world of ethical investing.",
  },
];

const testimonials = [
  {
    testimonial:
      "Halvest has presented me with multi currency investment opportunities with very attractive returns which are paid as and when due.",
    author: "Jumoke Odulaja",
    time: "(Halvest member since 2021)",
  },
  {
    testimonial:
      " I can invest my money and sleep with my two eyes closed knowing that it is being handled by experts who would take every precaution to guarantee the safety of my asset.",
    author: "Funmi Abdulrahman",
    time: "(Halvest member since 2021)",
  },
  {
    testimonial:
      "As a Muslim, I've found in Halvest a reliable and trustworthy partner in my financial journey",
    author: "Mustapha Salaudeen",
    time: "(Member since 2022)",
  },
];

const WhatYouWillGet = () => {
  const [active, setActive] = useState(0);
  const [currTestimonial, setCurrTestimonial] = useState(0);
  return (
    <section className="bg-white border-b py-8">
      <div className="container mx-auto m-8">
        <div className="max-w-[756px] m-8">
          <h1 className="w-full  text-4xl font-[600]   leading-tight  text-[#101828]">
            What You’ll Get
          </h1>
          <h5 className="w-full mt-5 text-xl font-[400] leading-tight  text-[#475467]">
            We are changing the ethical investment game for good through access
            to:
          </h5>
        </div>
      </div>

      <div className="container mx-auto flex my-16 gap-16 flex-col lg:flex-row   min-h-[512px]">
        <div className="flex-1 min-h-[512px]">
          {items.map((item, index) => (
            <div
              onClick={() => {
                setActive(index);
              }}
              key={index}
              className={`max-w-[526px] cursor-pointer py-4 px-6 border-l-4 ${
                index === active ? "border-l-[#1BC3D5]" : "border-l-transparent"
              }`}
            >
              <h1 className="w-full  text-xl font-[600]   leading-tight  text-[#101828]">
                {item.title}
              </h1>
              <h5 className="w-full mt-1 text-base font-[400] leading-tight  text-[#475467]">
                {item.desc}
              </h5>
            </div>
          ))}
        </div>
        <div className="flex-1 min-h-[512px]">
          <img
            style={{ display: active === 0 ? "block" : "none" }}
            src={require("../Assets/Images/Content.png")}
          />
          <img
            style={{ display: active === 1 ? "block" : "none" }}
            src={require("../Assets/Images/Content2.png")}
          />

          <img
            style={{ display: active === 2 ? "block" : "none" }}
            src={require("../Assets/Images/Content3.png")}
          />
        </div>
      </div>
      <div
        style={{
          backgroundImage: 'url("/Assets/line-pattern.png")',

          backgroundColor: "#159AA8",

          backgroundPosition: "center",
          // backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
        }}
        className=" lg:container max-w-[1216px]  rounded-[24px] my-16 mx-8 lg:mx-auto min-h-[420px] py-6 px-[28px] md:px[38px] lg:px-[68px] gap-8 lg:gap-[71px]   flex  justify-between items-center  flex-wrap"
      >
        <img
          src={require("../Assets/Images/Image9.png")}
          className="w-[300px] object-cover object-top rounded-2xl h-[200px] lg:w-[385px] lg:h-[308px]"
        />
        <div className="flex flex-1 flex-col">
          <div className="flex gap-1 items-center">
            <BsStarFill size={"20px"} color="#FFF" />
            <BsStarFill size={"20px"} color="#FFF" />
            <BsStarFill size={"20px"} color="#FFF" />
            <BsStarFill size={"20px"} color="#FFF" />
            <BsStarFill size={"20px"} color="#FFF" />
          </div>
          <h5 className="w-full mt-8 mb-12 text-4xl font-[500] leading-[44px]  text-[#fff]">
            {testimonials[currTestimonial].testimonial}
          </h5>
          <div className="flex justify-between items-center">
            <div>
              <h5 className="w-full mb-1 text-[18px] font-[600] leading-7  text-[#fff]">
                {testimonials[currTestimonial].author}
              </h5>
              <h5 className="w-full  text-[16px] font-[400] leading-6  text-[#D0D5DD]">
                {testimonials[currTestimonial].time}
              </h5>
            </div>
            <div className="flex gap-8">
              <div
                onClick={() => {
                  setCurrTestimonial((prev) => {
                    const prevTestimonialIndex = prev - 1;
                    mixpanel.track(
                      `Opened-Testimonial-${prevTestimonialIndex}`
                    );
                    if (prevTestimonialIndex >= 0) {
                      return prevTestimonialIndex;
                    } else {
                      return testimonials.length - 1;
                    }
                  });
                }}
                className="flex w-14 h-14 cursor-pointer transform transition hover:scale-105 duration-300 ease-in-out rounded-full justify-center items-center border border-[#EAECF0]"
              >
                <IoArrowBack size={20} color="#fff" />
              </div>
              <div
                onClick={() => {
                  setCurrTestimonial((prev) => {
                    const next = prev + 1;
                    if (next + 1 <= testimonials?.length) {
                      mixpanel.track(`Opened-Testimonial-${next}`);
                      return next;
                    } else {
                      mixpanel.track(`Opened-Testimonial-${1}`);
                      return 0;
                    }
                  });
                }}
                className="flex w-14 h-14  cursor-pointer transform transition hover:scale-105 duration-300 ease-in-out rounded-full justify-center items-center border border-[#EAECF0]"
              >
                <IoArrowForward size={20} color="#fff" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhatYouWillGet;
