import React from "react";
import { IoArrowForward } from "react-icons/io5";
import ChatRose from "../Assets/SVGS/chart-rose";

const Opportunities = () => {
  return (
    <section className="bg-white border-b md:px-6 xl:px-0 py-8">
      <div className="container max-w-[90%] lg:max-w-[1128px]  mx-auto m-8">
        <div className="flex gap-4 flex-col xl:flex-row ">
          <h1 className="text-4xl font-[600] flex-1 leading-tight  text-[#101828]">
            Here are our investment opportunities
          </h1>
          <h5 className=" text-xl flex-1 font-[400] leading-tight  text-[#475467]">
            We help our investors have a balanced portfolio through investments
            in different asset classes.
          </h5>
        </div>
      </div>
      <div className="max-w-[1158px] gap-3 my-16 mx-auto min-h-[360px] flex justify-center lg:justify-between lg:items-end flex-wrap">
        <div className="flex gap-3 py-[25px] px-8 rounded-2xl h-[448px] hover:h-[464px] transition-[height]  duration-500 ease-linear w-[374px] max-w-[90%] flex-col  border border-[rgba(255,255,255,0.30)] bg-[#159AA8]">
          <div className="flex-[2]  flex  items-center">
            <img
              className="w-[60px] h-[60px]  "
              src={require("../Assets/Icons/elements-1.png")}
            />
          </div>
          <div className="h-[1px] w-full bg-white opacity-[0.72]"></div>
          <div className="flex-[3] flex flex-col justify-between  ">
            <h5 className="font-[600] leading-[30px] text-[20px] text-[#fff]">
              SME Financing
            </h5>
            <h5 className="font-[400] leading-6 text-[16px] text-[#fff]">
              Earn monthly passive income by investing in our flagship and
              highly rewarding SME financing projects. Suitable for investors
              looking for short term investments with competitive returns.
            </h5>
            {/* <div className="flex items-center gap-2">
              <h5 className="font-[600] leading-6 text-[16px]  text-[#fff]">
                Learn more
              </h5>
              <IoArrowForward size={20} color="#fff" />
            </div> */}
          </div>
        </div>
        <div className="flex gap-3 py-[25px] px-8 rounded-2xl h-[448px] hover:h-[464px] transition-[height]  duration-500 ease-linear w-[374px] max-w-[90%] flex-col  border border-[rgba(255,255,255,0.30)] bg-[#45C8A1]">
          <div className="flex-[2]  flex  items-center">
            <img
              className="w-[60px] h-[60px] "
              src={require("../Assets/Icons/real-estate-2.png")}
            />
          </div>
          <div className="h-[1px] justify-center items-center w-full bg-white opacity-[0.72]"></div>
          <div className="flex-[3] flex flex-col justify-between  ">
            <h5 className="font-[600] leading-[30px] text-[20px] text-[#fff]">
              Real Estate Investing
            </h5>
            <h5 className="font-[400] leading-6 text-[16px] text-[#fff]">
              Co-invest with fellow investors in international real estate
              ventures and receive monthly or quarterly rental income, all in
              USD, without any interest.
            </h5>
            {/* <div className="flex items-center gap-2">
              <h5 className="font-[600] leading-6 text-[16px]  text-[#fff]">
                Learn more
              </h5>
              <IoArrowForward size={20} color="#fff" />
            </div> */}
          </div>
        </div>
        <div className="flex gap-3 py-[25px] px-8 rounded-2xl h-[448px] hover:h-[464px] transition-[height]  duration-500 ease-linear w-[374px] max-w-[90%] flex-col  border border-[rgba(255,255,255,0.30)] bg-[#F9F871]">
          <div className="flex-[2]  flex  items-center">
            {/* <img
              className="w-[60px] h-[60px] "
              src={require("../Assets/Icons/chart-rose-3.png")}
            
            /> */}
            <ChatRose />
          </div>
          <div className="h-[1px] justify-center items-center w-full bg-[#101828] opacity-[0.72]"></div>
          <div className="flex-[3] flex flex-col justify-between  ">
            <h5 className="font-[600] leading-[30px] text-[20px] text-[#101828]">
              Startup Investing
            </h5>
            <h5 className="font-[400] leading-6 text-[16px] text-[#101828]">
              Join other angel investors to invest in the next “Microsoft or
              Google” at the pre-seed / seed stage using our ethical and ESG
              focused investing model.
            </h5>
            {/* <div className="flex items-center gap-2">
              <h5 className="font-[600] leading-6 text-[16px]  text-[#101828]">
                Learn more
              </h5>
              <IoArrowForward size={20} color="#fff" />
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Opportunities;
