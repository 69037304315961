import React, { useEffect, useState } from "react";
import Header from "../Components/Header";
import { FiPlusCircle, FiMinusCircle } from "react-icons/fi";
import Footer from "../Components/Footer";
import mixpanel from "mixpanel-browser";
import SEO from "../Components/Seo";

const AboutUs = () => {
  const [currentPhilosophies, setCurrentPhilosophies] = useState(null);
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const Philosophies = [
    {
      title: "Ethical:",
      answer:
        "We ensure that all our investments and activities are guided by the rules of the Shariah and are in line with ethical principles. We are not shy to decline investments that go against our philosophy regardless of the financial rewards.",
    },
    {
      title: "Real Economy:",
      answer:
        "We're here to support the real economy, where businesses grow, and communities thrive. We're all about empowering those who are engaged in tangible economic activities.",
    },
    {
      title: "Asset-Backed Financing:",
      answer:
        "We believe in responsible investing. Our investment projects are asset-backed, ensuring that every transaction holds genuine value and contributes to the creation of wealth rooted in substance, not speculation.",
    },
    {
      title: "Transparency:",
      answer:
        "We keep it honest. We're open about the risk and rewards of our investments. Before you drop a penny into our project, we make sure you have all the information that you need to make an informed decision. Trust and clarity are non-negotiable.",
    },
    {
      title: "ESG focused",
      answer:
        "We love businesses and projects that care about the environment, society, and doing what's right.",
    },
  ];

  const [animate, setAnimate] = useState(false);
  const renderPhilosophies = (item, index) => {
    return (
      <div
        key={index}
        className="w-[85%] lg:w-[458px] my-14 flex justify-between "
      >
        <div className="w-[94%]">
          <h1 className="  text-xl font-[600]   leading-[30px]  text-[#101828]">
            {item.title}
          </h1>

          <h5
            className={`mt-2 text-base font-[400] leading-6  text-[#475467] transform transition-all duration-500 ease-in-out ${
              animate ? "opacity-100 translate-x-0" : "opacity-0 translate-x-3"
            }`}
            style={{
              display: index === currentPhilosophies ? "block" : "none",
            }}
          >
            {item.answer}
          </h5>
        </div>
        <div className="w-[5%]">
          {index === currentPhilosophies ? (
            <FiMinusCircle
              onClick={() => {
                setCurrentPhilosophies(-1);
              }}
              color={"#98A2B3"}
              size={"24px"}
              className="cursor-pointer transform transition hover:scale-105 duration-400 ease-in-out"
            />
          ) : (
            <FiPlusCircle
              className="cursor-pointer transform transition hover:scale-105 duration-400 ease-in-out"
              onClick={() => {
                setAnimate(false);
                mixpanel.track(`Opened-Our-Philosophy-${index + 1}`);
                setCurrentPhilosophies(index);
                setTimeout(() => {
                  setAnimate(true);
                }, []);
              }}
              color={"#98A2B3"}
              size={"24px"}
            />
          )}
        </div>
      </div>
    );
  };
  return (
    <div>
      <SEO
        title={"About Halvest | Empowering Ethical Investments in Africa"}
        description={
          "Learn more about Halvest and our mission to become Africa's #1 digital investment platform."
        }
        name={"Halvest"}
        type={"article"}
      />
      <Header />
      <section className="bg-white pt-24 border-b pb-8">
        <div className="container px-10  mx-auto m-8">
          <div className=" flex flex-col justify-center items-center ">
            <h1 className="w-full  text-4xl  font-[600] max-w-[768px] mx-[auto] leading-tight text-center text-[#101828]">
              We are building the biggest global investment community for
              ethical investors.
            </h1>
            <h5 className="w-full mt-5 text-xl font-[400] max-w-[768px] mx-[auto] leading-tight text-center text-[#475467]">
              At Halvest, we believe you deserve competitive returns on
              investments without interest or guilt.
            </h5>
            {/* <button
              onClick={() => {
                window.location = "https://app.halvestco.com/register";
              }}
              className="rounded-[8px] text-[18px] w-[290px] h-[52px] flex justify-center items-center mx-auto lg:mx-0 bg-[#159AA8] text-[#fff] font-bold mt-16 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
            >
              Get Started
            </button> */}
          </div>
          <img
            className="w-full  mt-[64px]"
            src={require("../Assets/Images/about us.png")}
          />
        </div>
      </section>
      <section className="bg-white border-b pb-8">
        <div className="container  w-[90%] lg: mx-auto m-8">
          <div className="max-w-[756px] my-8">
            <h1 className="w-full  text-4xl font-[600]   leading-tight  text-[#101828]">
              Our core values
            </h1>
            <h5 className="w-full mt-5 text-xl font-[400] leading-tight  text-[#475467]">
              We live by three core values (ICE)
            </h5>
          </div>
          <div className="flex my-[75px] flex-wrap gap-6 justify-center items-center">
            {/* <div className="flex p-6 flex-col h-[366px] w-[286px] gap-[64px] rounded-2xl bg-[#F9FAFB] ">
              <img
                src={require("../Assets/Images/Featured icon.png")}
                className="w-12 h-12"
              />
              <div>
                <h1 className="w-full  text-xl font-[600]   leading-[30px]  text-[#101828]">
                  Sustainability
                </h1>
                <h5 className="w-full mt-2 text-base font-[400] leading-6  text-[#475467]">
                  By sustainability, we mean generational wealth. We are focused
                  on helping our members attain financial freedom and building
                  everlasting wealth so they can leave a good legacy behind.
                </h5>
              </div>
            </div> */}
            {/* <div className="flex p-6 flex-col h-[366px] w-[286px] gap-[64px] rounded-2xl bg-[#F9FAFB] ">
              <img
                src={require("../Assets/Images/Featured icon (1).png")}
                className="w-12 h-12"
              />
              <div>
                <h1 className="w-full  text-xl font-[600]   leading-[30px]  text-[#101828]">
                  Halal
                </h1>
                <h5 className="w-full mt-2 text-base font-[400] leading-6  text-[#475467]">
                  We ensure that all our investments and activities are guided
                  by the rules of the Shariah. We are not shy to decline
                  investments that are not in line with our faith regardless of
                  the returns.
                </h5>
              </div>
            </div> */}
            <div className="flex p-6 flex-col h-[366px] w-[286px] gap-[64px] rounded-2xl bg-[#F9FAFB] ">
              <img
                src={require("../Assets/Images/Featured icon (2).png")}
                className="w-12 h-12"
              />
              <div>
                <h1 className="w-full  text-xl font-[600]   leading-[30px]  text-[#101828]">
                  Innovation
                </h1>
                <h5 className="w-full mt-2 text-base font-[400] leading-6  text-[#475467]">
                  We leverage technology that is required to run our operations
                  so we can continuously deliver excellent services to our
                  members.
                </h5>
              </div>
            </div>
            <div className="flex p-6 flex-col h-[366px] w-[286px] gap-[64px] rounded-2xl bg-[#F9FAFB] ">
              <img
                src={require("../Assets/Images/Featured icon (3).png")}
                className="w-12 h-12"
              />
              <div>
                <h1 className="w-full  text-xl font-[600]   leading-[30px]  text-[#101828]">
                  Community
                </h1>
                <h5 className="w-full mt-2 text-base font-[400] leading-6  text-[#475467]">
                  We embrace the spirit of one for all, all for one. We thrive
                  on the success of our investors in the community and we take
                  their interest as our priority.
                </h5>
              </div>
            </div>
            <div className="flex p-6 flex-col h-[366px] w-[286px] gap-[64px] rounded-2xl bg-[#F9FAFB] ">
              <img
                src={require("../Assets/Images/Featured icon.png")}
                className="w-12 h-12"
              />
              <div>
                <h1 className="w-full  text-xl font-[600]   leading-[30px]  text-[#101828]">
                  Excellence
                </h1>
                <h5 className="w-full mt-2 text-base font-[400] leading-6  text-[#475467]">
                  We are driven by the pursuit of excellence in every aspect of
                  our operations including our team, product, branding,
                  transparency, content and messaging e.t.c.
                </h5>
              </div>
            </div>
          </div>
          <div className="bg-[#F9FAFB] border-[6px] flex-col lg:flex-row mx-auto w-full my-[100px] flex min-h-[662px] border-[#159AA8] rounded-2xl ">
            <div className="flex-1  pl-[14px] pr-[20px] py-[30px] lg:pl-[54px] lg:pr-[71px]">
              <h1 className="w-full  text-4xl font-[600]   leading-tight  text-[#101828]">
                Our Philosophy
              </h1>
              <h5 className="w-full inline mt-3 mb-[39px] text-base font-[400] leading-tight  text-[#475467]">
                Our PhilosophyOur philosophy is grounded in five core pillars{" "}
                <h5 className="text-base inline font-[600] leading-tight  text-[#101828]">
                  (ERATE):
                </h5>
              </h5>
              <div>{Philosophies.map(renderPhilosophies)}</div>
            </div>
            <div className="flex-1">
              <img
                src={require("../Assets/Images/story.png")}
                className="w-full h-full"
              />
            </div>
          </div>
        </div>
      </section>
      {/* <section className="bg-white border-b pb-8">
        <div className="max-w-[756px] px-10 my-[60px] mx-auto">
          <h1 className="  text-4xl font-[600] text-center   leading-tight  text-[#101828]">
            Advisory Board
          </h1>
          <h5 className=" mt-5 text-xl font-[400] text-center leading-tight  text-[#475467]">
            Our philosophy is simple — a team of diverse, passionate people and
            a culture that empowers us to do our best work.
          </h5>
        </div>
        <div className=" container gap-6 lg:gap-4  m-auto flex-col lg:flex-row flex justify-between items-center">
          <div className="w-[90%] lg:w-[384px] flex flex-col h-[426px]">
            <img
              src={require("../Assets/Images/board-1.png")}
              className="w-full h-[296px]"
              alt=""
            />
            <div className="flex-1 justify-center items-center">
              <h5 className=" mb-1 mt-6 text-xl font-[400] text-center leading-tight  text-[#475467]">
                Amélie Laurent
              </h5>
              <h5 className=" text-xl font-[400] text-center leading-tight  text-[#475467]">
                Founder & CEO
              </h5>
              <div className="flex mt-6 gap-5 justify-center items-center">
                <img
                  src={require("../Assets/Icons/linkedin.png")}
                  width="20px"
                  height="20px"
                  alt="logo"
                  className="object-contain cursor-pointer"
                />
                <img
                  src={require("../Assets/Icons/facebook.png")}
                  width="20px"
                  height="20px"
                  alt="logo"
                  className="object-contain cursor-pointer"
                />
              </div>
            </div>
          </div>
          <div className="w-[90%] lg:w-[384px] flex flex-col h-[426px]">
            <img
              src={require("../Assets/Images/board-2.png")}
              className="w-full h-[296px]"
              alt=""
            />
            <div className="flex-1 justify-center items-center">
              <h5 className=" mb-1 mt-6 text-xl font-[400] text-center leading-tight  text-[#475467]">
                Amélie Laurent
              </h5>
              <h5 className=" text-xl font-[400] text-center leading-tight  text-[#475467]">
                Founder & CEO
              </h5>
              <div className="flex mt-6 gap-5 justify-center items-center">
                <img
                  src={require("../Assets/Icons/linkedin.png")}
                  width="20px"
                  height="20px"
                  alt="logo"
                  className="object-contain cursor-pointer"
                />
                <img
                  src={require("../Assets/Icons/facebook.png")}
                  width="20px"
                  height="20px"
                  alt="logo"
                  className="object-contain cursor-pointer"
                />
              </div>
            </div>
          </div>
          <div className="w-[90%] lg:w-[384px] flex flex-col h-[426px]">
            <img
              src={require("../Assets/Images/board-3.png")}
              className="w-full h-[296px]"
              alt=""
            />
            <div className="flex-1 justify-center items-center">
              <h5 className=" mb-1 mt-6 text-xl font-[400] text-center leading-tight  text-[#475467]">
                Amélie Laurent
              </h5>
              <h5 className=" text-xl font-[400] text-center leading-tight  text-[#475467]">
                Founder & CEO
              </h5>
              <div className="flex mt-6 gap-5 justify-center items-center">
                <img
                  src={require("../Assets/Icons/linkedin.png")}
                  width="20px"
                  height="20px"
                  alt="logo"
                  className="object-contain cursor-pointer"
                />
                <img
                  src={require("../Assets/Icons/facebook.png")}
                  width="20px"
                  height="20px"
                  alt="logo"
                  className="object-contain cursor-pointer"
                />
              </div>
            </div>
          </div>
        </div>
          </section> */}
      {/* <section className="bg-white border-b pb-8">
        <div className="max-w-[756px] my-[60px] mx-auto">
          <h1 className="  text-4xl font-[600] text-center   leading-tight  text-[#101828]">
            Meet our team
          </h1>
          <h5 className=" mt-5 px-3 text-xl font-[400] text-center leading-tight  text-[#475467]">
            Our philosophy is simple — a team of diverse, passionate people and
            a culture that empowers us to do our best work.
          </h5>
        </div>
        <div className="flex container m-auto flex-wrap px-14 justify-center gap-10 lg:gapx-8 lg:gap-y-16  ">
          <div className="w-[217px]  flex items-center flex-col h-[204px]">
            <img
              src={require("../Assets/Images/Ridwan.jpeg")}
              className="w-[120px] h-[120px] rounded-full   object-cover"
              alt=""
            />
            <div className=" mt-5 justify-center items-center">
              <h5 className=" mb-1  text-xl font-[400] text-center leading-tight  text-[#475467]">
                Ridwan Sanusi
              </h5>
              <h5 className=" text-xl font-[400] text-center leading-tight  text-[#475467]">
                Founder & CEO
              </h5>
              <div className="flex mt-4 gap-5 justify-center items-center">
                <img
                  src={require("../Assets/Icons/linkedin.png")}
                  width="30px"
                  height="30px"
                  alt="logo"
                  className="object-contain cursor-pointer"
                />
              </div>
            </div>
          </div>
          <div className="w-[217px] flex items-center flex-col ">
            <img
              src={require("../Assets/Images/Faaiz.jpeg")}
              className="w-[120px] h-[120px] rounded-full   object-cover"
              alt=""
            />

            <div className=" mt-5 justify-center items-center">
              <h5 className=" mb-1  text-xl font-[400] text-center leading-tight  text-[#475467]">
                Faaiz Hadaina
              </h5>
              <h5 className=" text-xl font-[400] text-center leading-tight  text-[#475467]">
                Tech Lead
              </h5>
              <div className="flex mt-4 gap-5 justify-center items-center">
                <a
                  href="https://www.linkedin.com/in/faaiz-hadaina/"
                  target="_blank"
                >
                  <img
                    src={require("../Assets/Icons/linkedin.png")}
                    width="30px"
                    height="30px"
                    alt="logo"
                    className="object-contain cursor-pointer"
                  />
                </a>
              </div>
            </div>
          </div>
         
        </div>
      </section> */}
      <section className="bg-white border-b pb-8">
        <div className="px-10 flex flex-col justify-center items-center my-[60px] ">
          <h1 className="  text-4xl font-[600] text-center   leading-tight  text-[#101828]">
            Get started with Halvest today, fast.
          </h1>
          <h5 className=" mt-5 text-xl font-[400] text-center leading-tight  text-[#475467]">
            We have cracked the ethical investing code.
          </h5>
          <button
            onClick={() => {
              mixpanel.track("Get-Started-Clicked");
              window.location = "https://app.halvestco.com/register";
            }}
            className="rounded-[8px] text-[18px] w-[290px] h-[52px] flex justify-center items-center mx-auto lg:mx-0 bg-[#159AA8] text-[#fff] font-bold mt-8 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
          >
            Get Started
          </button>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default AboutUs;
