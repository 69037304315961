import React, { useState } from "react";
import { HiOutlineChevronDown } from "react-icons/hi";
import { FaChevronDown } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import mixpanel from "mixpanel-browser";

const Menu = ({ menuOpen, setMenuOpen }) => {
  const pathName = useLocation().pathname;
  const navigate = useNavigate();

  const handleClickLink = (param) => {
    navigate(param);
    setMenuOpen(false);
  };
  const links = [
    // { id: 1, textLink: "Home", link: "/" },
    { id: 3, textLink: "About Us", link: "/about-us" },
    // { id: 2, textLink: "Our Story", link: "/our-story" },
    { id: 1, textLink: "Membership", link: "/membership" },
  ];
  return (
    <div className="">
      <div className="flex flex-col items-center menu-height lg:hidden ">
        <div className="pt-20">
          <ul className="">
            {links.map(({ id, textLink, link, hasOthers }) => (
              <li
                className={`text-black font-normal flex gap-1 justify-center items-center text-lg py-6 text-center ${
                  pathName == link && "active2"
                }`}
                key={id}
                onClick={() => handleClickLink(link)}
              >
                <p href={link}>{textLink}</p>
                {hasOthers && (
                  <HiOutlineChevronDown
                    className="group-hover:text-gray-800 mt-[1px]"
                    size={20}
                    color="#475467"
                  />
                )}
              </li>
            ))}
          </ul>

          <div
            onClick={() => {
              mixpanel.track("Log-In-Clicked");
              window.location = "https://app.halvestco.com/";
            }}
            className="flex justify-center items-center  my-6 w-[180px] h-[40px] rounded-[8px]"
          >
            <h4 className="inline-block text-[#475467] cursor-pointer no-underline hover:text-gray-800 text-base font-semibold ">
              Log in
            </h4>
          </div>
          <div
            onClick={() => {
              mixpanel.track("Sign-Up-Clicked");
              window.location = "https://app.halvestco.com/register";
            }}
            className="flex justify-center items-center bg-[#159AA8] my-6 w-[180px] h-[40px] rounded-[8px]"
          >
            <h4 className=" text-[#fff] no-underline cursor-pointer text-base font-semibold  ">
              Sign up
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

const Header = () => {
  const pathname = useLocation().pathname;
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <>
      <nav id="header" className="fixed py-[18px]   w-full z-30 top-0 bg-white">
        <div className="w-full container mx-auto flex  items-center justify-between mt-0 py-2">
          <div className="flex gap-10 items-center">
            <div className="pl-4 flex items-center">
              <img
                onClick={() => {
                  // mixpanel.track_pageview({ page: "Website-Home-Page" });
                  navigate("/");
                }}
                src={require("../Assets/Logo/halvest.png")}
                className="w-[158px] cursor-pointer h-[26px]"
              />
            </div>
            <div className="w-full flex-grow lg:flex lg:items-center lg:w-auto hidden mt-2 lg:mt-0 bg-white lg:bg-transparent text-black p-4 lg:p-0 z-20">
              <ul className="list-reset lg:flex justify-end flex-1 items-center">
                {/* <li
                  onClick={() => {
                    navigate("/");
                  }}
                  className="mr-3 cursor-pointer"
                >
                  <p
                    style={{ color: pathname === "/" ? "#159AA8" : "#475467" }}
                    className="inline-block text-[#475467] no-underline hover:text-[#159AA8] text-base font-semibold py-2 px-4   transition duration-1000 ease-in-out"
                    // href="#"
                  >
                    Home
                  </p>
                </li> */}

                <li
                  onClick={() => {
                    // mixpanel.track_pageview({ page: "Website-AboutUs-Page" });
                    navigate("/about-us");
                  }}
                  className="mr-3 group flex items-center gap-2 px-4  py-2 cursor-pointer"
                >
                  <p
                    style={{
                      color: pathname === "/about-us" ? "#159AA8" : "#475467",
                    }}
                    className=" group-hover:text-[#159AA8] inline-block text-[#475467] no-underline  text-base font-semibold  transition duration-300 ease-in-out "
                  >
                    About Us
                  </p>
                </li>
                {/* <li
                  onClick={() => {
                    navigate("/our-story");
                  }}
                  className="group mr-3 flex items-center gap-2 px-4  py-2 cursor-pointer"
                >
                  <p
                    style={{
                      color: pathname === "/our-story" ? "#159AA8" : "#475467",
                    }}
                    className="group-hover:text-[#159AA8] inline-block text-[#475467] no-underline  text-base font-semibold  transition duration-300 ease-in-out"
                  >
                    Our Story
                  </p>
                </li> */}
                <li
                  onClick={() => {
                    // mixpanel.track_pageview({
                    //   page: "Website-Membership-Page",
                    // });
                    navigate("/membership");
                  }}
                  className="mr-3 cursor-pointer"
                >
                  <p
                    style={{
                      color: pathname === "/membership" ? "#159AA8" : "#475467",
                    }}
                    className="inline-block text-[#475467] no-underline hover:text-[#159AA8] text-base font-semibold py-2 px-4  transition duration-300 ease-in-out"
                  >
                    Membership
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="block lg:hidden pr-4">
            <button
              id="nav-toggle"
              className="flex items-center p-1 text-[#475467] hover:text-gray-900 focus:outline-none focus:shadow-outlinet"
            >
              {!menuOpen ? (
                <svg
                  onClick={() => setMenuOpen(!menuOpen)}
                  className="fill-current h-6 w-6"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Menu</title>
                  <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                </svg>
              ) : (
                <span onClick={() => setMenuOpen(!menuOpen)}>
                  <AiOutlineClose size={24} color="black" />
                </span>
              )}
            </button>
          </div>
          <div className="hidden gap-2 lg:flex ">
            <div
              onClick={() => {
                mixpanel.track("Log-In-Clicked");
                window.location = "https://app.halvestco.com/";
              }}
              // className=" transform transition hover:scale-105 duration-300 ease-in-out"
            >
              <h4 className="inline-block text-[#475467] cursor-pointer no-underline hover:text-gray-800 text-base font-semibold py-2 px-4">
                Log in
              </h4>
            </div>
            <div
              onClick={() => {
                mixpanel.track("Sign-Up-Clicked");
                window.location = "https://app.halvestco.com/register";
              }}
              className="flex justify-center items-center bg-[#159AA8] w-[92px] h-[44px] rounded-[8px]  transform transition hover:scale-105 duration-300 ease-in-out"
            >
              <h4 className="inline-block text-[#fff] no-underline cursor-pointer text-base font-semibold  ">
                Sign up
              </h4>
            </div>
          </div>
        </div>
        <hr className="border-b border-gray-100 opacity-25 my-0 py-0" />
      </nav>
      {menuOpen && (
        <div
          className={`fixed mt-[80px] w-full h-full z-50 bg-white ${
            menuOpen ? "menu-wrapper " : " menu-hidden"
          }`}
        >
          <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        </div>
      )}
    </>
  );
};

export default Header;
