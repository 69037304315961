import AboutUs from "./Pages/AboutUs";
import Home from "./Pages/Home";
import Pricing from "./Pages/Pricing";
import Story from "./Pages/Story";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import mixpanel from "mixpanel-browser";
import { useEffect } from "react";
import Whatsapp from "./Components/Whatsapp";
import { useCookies } from "react-cookie";
import { HelmetProvider } from "react-helmet-async";

// const PROJECT_TOKEN = "20bc20e92982f5e45dccfa3e164e4739";
const PROJECT_TOKEN = "20bc20e92982f5e45dccfa3e164e4739";

// Replace YOUR_TOKEN with your Project Token
mixpanel.init(PROJECT_TOKEN, {
  debug: true,
  ignore_dnt: true,
  track_pageview: "full-url",
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/about-us",
    element: <AboutUs />,
  },
  // {
  //   path: "/our-story",
  //   element: <Story />,
  // },
  {
    path: "/membership",
    element: <Pricing />,
  },
  {
    path: "*",
    element: <Navigate to={"/"} />,
  },
]);

function App() {
  useEffect(() => {
    mixpanel.track("Website-URL-Launched");
  }, []);

  return (
    <>
      <HelmetProvider>
        <RouterProvider router={router} />;
        <Whatsapp />
      </HelmetProvider>
    </>
  );
}

export default App;
